import React from 'react'
import { InfoSection } from '../../components';
import { Helmet } from 'react-helmet';
import { aboutObjOne } from "./Data";
import './Contact.css';

function Contact() {
    return (
        <>
            <Helmet>
                <title>Contact - Calicamo Financial Services</title>
                <meta
                    charSet="utf-8"
                    name="description"
                    content="Calicamo Financial Services ensures its agency puts customer service first."
                />
                <link rel="canonical" href="https://calicamofinancials.com" />
            </Helmet>
            <InfoSection {...aboutObjOne} />
            <div className="contact-container">
                <div className="contact-text">
                    <div className="contact-info">
                        <p className="contact-statement">
                            Give us a call at <br />
                            <a href="tel:13607467197">1-360-746-7197</a>
                        </p>
                        <p className="contact-statement">
                            E-mail us at <br />
                            <a href="mailto:calicamofinancials@outlook.com">calicamofinancials@outlook.com</a>
                        </p>
                    </div>
                </div>
                <div className="address">
                    <h3>Our Office</h3>
                    <p>4200 Meridian St #213
                        <br />Bellingham, WA 98226, USA</p>

                    <div className="map-container">
                        <iframe
                            title="Google Map"
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5139.370872965762!2d-122.48950047363589!3d48.79353708049254!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5485a4a7a9eade63%3A0x132599ea738f6a78!2s4200%20Meridian%20St%20%23213%2C%20Bellingham%2C%20WA%2098226%2C%20USA!5e0!3m2!1sen!2smx!4v1732112289834!5m2!1sen!2smx"
                            width="100%"
                            height="300"
                            style={{ border: 0 }}
                            allowFullScreen=""
                            loading="lazy"
                        ></iframe>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Contact
